.accordion {
    overflow: hidden;
    border: none;
    border-radius: 12px;
    max-width: 800px;
    width: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px 15px 25px;
    cursor: pointer;
    background-color: #F8F8FB;
  }

  .header h2 {
    color: #525252;
    font-size: 17px;
  }
  
  .content {
    background-color: #F8F8FB;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }
  .content p {
    color: #525252;
    margin: 16px 40px 16px 26px;
  }