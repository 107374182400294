@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
}

h1 {
    margin: 0;
}

h2 {
    margin: 0;
}

h3 {
    margin: 0;
    line-height: 1;
}

h4 {
    margin: 0;
}

h5 {
    margin: 0;
}

h6 {
    margin: 0;
}

p {
    margin: 0;
}

button {
    font-size: 14px;
}

@media only screen and (max-width: 1024px) {
    .sub1main1 {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
    .sub1main1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        background-color: white;
        border-radius: 100px;
        padding: 15px 25px 15px 25px;
    }
    
    .sub1main1 button {
        background-color: #FF6060;
        border-radius: 100px;
        padding: 13px 25px 13px 25px;
        border: none;
        color: white;
        font-weight: bold;
        transition: all 0.3s;
        cursor: pointer;
    }
    
    .sub1main1 button:hover {
        transition: all 0.3s;
        background-color: #ff3030;
    }
    
    .sub1main1 img {
        display: flex;
        width: 169px;
        height: auto;
    }
    
    .headerlist {
        display: flex;
        align-items: center;
        gap: 50px;
    }
    
    .headerlist p {
        color: #525252;
        font-size: 16px;
        cursor: pointer;
    }
    
    .bar {
        height: 2px;
        background-color: #FF6060;
        width: 0;
        border-radius: 100px;
        transition: all 0.3s;
    }
    
    .barAnimation {
        width: 100%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container img {
    user-select: none;
}

.content {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    width: 100%;
}

.main1 {
    background-color: #2F67F8;
}

.main1sub1 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 20px;
}

.main1sub1 button {
    border: 2px solid white;
    border-radius: 100px;
    background-color: transparent;
    color: white;
    padding: 10px 15px 10px 15px;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    transition: all 0.3s;
}

.main1sub1 button:hover {
    background-color: rgba(255, 255, 255, 0.308);
    transition: all 0.3s;
}

.fronttitle {
    z-index: 1;
    position: relative;
}

.main1sub1 h1 {
    font-size: 36px;
    color: white;
    line-height: 1.3;
}

.main1sub1 h2 {
    font-size: 20px;
    color: #F8F5EE;
}

.main1sub1 p {
    font-size: 18px;
    color: #F8F5EE;
}

.main1sub1 strong {
    font-size: 40px;
    line-height: 1.2;
    color: white;
}

.main1sub1 span {
    font-size: 17px;
    color: white;
    font-weight: bold;
}

.main2 {
    display: flex;
    gap: 50px;
}

.main2sub2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.main2sub2 h3 {
    color: #24289B;
    font-size: 28px;
    font-weight: bold;
}

.main2sub2list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.main2sub2list > div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.main2sub2list > div img {
    width: 40px;
    height: auto;
}

.main2sub2list > div p {
    font-size: 16px;
    color: #525252;
}

.main2sub2 button {
    background-color: #FF6060;
    border-radius: 100px;
    padding: 13px 25px 13px 25px;
    border: none;
    color: white;
    font-weight: bold;
    transition: all 0.3s;
    cursor: pointer;
    max-width: 200px;
    width: 100%;
}

.main2sub2 button:hover {
    transition: all 0.3s;
    background-color: #ff3030;
}

.main3 {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.main3sub1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    max-width: 650px;
}

.main3sub1 h3 {
    color: #24289B;
    font-size: 28px;
    font-weight: bold;
}

.main3sub1 strong {
    font-size: 18px;
    color: #525252;
}

.main3sub1 p {
    font-size: 17px;
    color: #525252;
}

.main3sub2 {
    display: flex;
    gap: 15px;
  }

.main4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(/src//images//Main4i1.png), url(/src//images//Main4i2.png);
    background-repeat: no-repeat;
    background-size: auto, auto;
    background-position: top left, bottom right;
    background-position-x: 3%, 97%;
    background-position-y: 3%, 97%;
    gap: 25px;
    background-color: #24289B;
}

.main4sub1 h3 {
    color: white;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
}

.main4sub2 {
    display: flex;
    gap: 30px;
}

.main5 {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.main5sub1 {
    display: flex;
    justify-content: space-between;
}

.main5sub1sub1 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 700px;
    align-items: flex-start;
}

.main5sub1sub1 h3 {
    color: #24289B;
    font-size: 28px;
    font-weight: bold;
}

.main5sub1sub1 strong {
    font-size: 18px;
    color: #525252;
}

.main5sub1sub1 p {
    font-size: 17px;
    color: #525252;
}

.main5 button {
    background-color: #FF6060;
    border-radius: 100px;
    padding: 10px 30px 10px 30px;
    border: none;
    color: white;
    font-weight: bold;
    transition: all 0.3s;
    cursor: pointer;
    max-width: 200px;
    white-space: nowrap;
}

.main5 button:hover {
    transition: all 0.3s;
    background-color: #ff3030;
}

.main5sub2 {
    display: flex;
    gap: 50px;
}

.video img{
    width: 100%;
    height: auto;
}

.main6 {
    display: flex;
    gap: 30px;
    background-position: bottom;
}

.main6sub1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.main6sub1 h3 {
    color: #24289B;
    font-size: 28px;
    font-weight: bold;
}

.main6sub1 button {
    background-color: #FF6060;
    border-radius: 100px;
    padding: 10px 30px 10px 30px;
    border: none;
    color: white;
    font-weight: bold;
    transition: all 0.3s;
    cursor: pointer;
    max-width: 200px;
    width: 100%;
}

.main6sub1 button:hover {
    transition: all 0.3s;
    background-color: #ff3030;
}

.main6sub1 > div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.main6sub2 img {
    width: 100%;
    max-width: 450px;
    height: auto;
}

.main7 {
    background-color: #2F67F8;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.main7 h3 {
    color: white;
    font-size: 28px;
    font-weight: bold;
}

.main7sub1 {
    display: flex;
    align-items: flex-start;
}

.main7sub1 > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main7sub1 p {
    color: white;
    font-size: 24px;
}

.main7sub1 button {
    border: 2px solid white;
    border-radius: 100px;
    background-color: transparent;
    color: white;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
}

.main7sub1 button img {
    width: 24px;
    height: auto;
}

.main7sub1 button:hover {
    background-color: rgba(255, 255, 255, 0.308);
    transition: all 0.3s;
}

.footerlist {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.footerlist p {
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.line2 {
    background-color: rgba(255, 255, 255, 0.473);
    height: 1px;
    border-radius: 100px;
    width: 100%;
}

.main7sub2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.main7sub2 img {
    display: flex;
    width: 169px;
    max-width: 100%;
    height: auto;
}

.main7sub3 {
    display: flex;
    justify-content: center;
    text-align: center;
}

.main7sub3 p {
    font-size: 15px;
    color: white;
}










@media only screen and (min-width: 1025px) {
    .video {
        width: 50%;
    }
    
    .slider {
        width: 60%;
        margin-left: -50px;
    }

    .main6 {
        background-position-x: 10%;
    }

    .main3 {
        background-image: url(/src//images//Main3.png);
        background-repeat: no-repeat;
        background-position-x: right;
    }
}


@media only screen and (max-width: 1024px) {
    .main5sub2 {
        flex-wrap: wrap;
    }

    .video {
        width: 100%;
    }

    .slider {
        width: 100%;
    }

    .main4sub2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .main6 {
        background-position-x: 5%;
    }

    .main1 {
        overflow: hidden;
    }

    .main1sub2 img {
        display: flex;
        max-width: 100%;
        height: auto;
    }

    .main1sub2 {
        margin-top: 10px;
        margin-right: -40px;
    }

    .main1 {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .main7sub2 {
        justify-content: center;
    }

    .mainheader {
        background-color: #2F67F8;
        padding: 30px 15px 0 15px;
    }

    .main6 {
        display: flex;
        flex-direction: column;
    }

    .main1 {
        padding: 30px 20px 0 20px;
    }
    
    .main2 {
        padding: 50px 20px 50px 20px;
        display: flex;
        flex-direction: column-reverse;
    }

    .main3 {
        padding: 50px 0 50px 0;
    }

    .main4 {
        padding: 50px 20px 50px 20px;
    }

    .main5 {
        padding: 50px 20px 50px 20px;
    }

    .main6 {
        padding: 50px 20px 50px 20px;
    }

    .main7 {
        padding: 50px 20px 30px 20px;
    }

    .main6sub1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main6sub1 h3 {
        text-align: center;
    }

    .main6sub2 {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-right: -20px;
    }
    
    .main2sub1 img {
        max-width: 100%;
        height: auto;
    }

    .main2sub1 {
        margin-left: -20px;
    }
    
    .main2sub2 {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .line1 {
        background-color: rgba(255, 255, 255, 0.473);
        width: 100%;
        max-width: 200px;
        border-radius: 100px;
        height: 1px;
    }

    .main7sub1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footerlist {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px 0 15px 0;
    }

    .main7sub2 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .footerheader {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footerheader2 > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .footerheader3 {
        margin: 30px 0 30px 0;
    }

    .footerheader3 > div {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .main5sub1 button {
        display: none;
    }

    .main5sub2 {
        justify-content: center;
    }

    .imggotas {
        position: absolute;
        margin-left: 215px;
        transform: rotate(-60deg);
    }

    .main1sub1 h1 {
        font-size: 30px;
        max-width: 450px;
    }

    .main1sub1 h2 {
        font-size: 19px;
        max-width: 500px;
    }

    .main1sub1 p {
        font-size: 17px;
        max-width: 500px;
    }

    .main2button {
        display: flex;
        justify-content: center;
    }

    .main3sub1 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .main3sub2 {
        overflow: hidden;
        overflow-x: auto;
        padding: 0 20px 10px 20px;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .main3sub2::-webkit-scrollbar {
        width: 0;
    }
}

@media only screen and (min-width: 769px) {
    .main6 {
        background-image: url(/src//images//Main6.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .mainheader {
        background-color: #2F67F8;
        padding: 30px 55px 0 55px;
    }

    .main1 {
        background-image: url(/src//images//Main1.png);
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-size: contain;
        padding: 30px 80px 50px 80px;
    }
    
    .main2 {
        padding: 80px 50px 80px 50px;
    }

    .main3 {
        padding: 50px 80px 100px 80px;
    }

    .main4 {
        padding: 50px 80px 70px 80px;
    }

    .main5 {
        padding: 100px 80px 50px 80px;
    }

    .main6 {
        padding:  100px 80px 150px 80px;
    }

    .main7 {
        padding: 50px 80px 20px 80px;
    }

    .main6sub2 img {
        display: none;
    }

    .main6sub1 {
        width: 50%;
    }

    .main6sub2 {
        width: 50%;
    }

    .main6sub1 h3 {
        margin-left: 25px;
    }

    .main6sub1 button {
        margin-left: 25px;
    }

    .main2sub1 {
        width: 50%;
        background-image: url(/src//images//Main2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
    }
    
    .main2sub1 img {
        display: none;
    }
    
    .main2sub2 {
        width: 50%;
    }

    .line1 {
        background-color: rgba(255, 255, 255, 0.473);
        width: 1px;
        border-radius: 100px;
        height: 130px;
        margin: 0 10% 0 10%;
    }

    /*.footerheader {
        width: 36%;
    }*/

    .footerheader > div {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .main5sub2 button {
        display: none;
    }

    .main1sub1 {
        max-width: 500px;
    }

    .main1sub1 h1 {
        max-width: 400px;
    }

    .main1sub2 {
        display: none;
    }

    .imggotas {
        position: absolute;
        margin: -5px 0 0 -50px;
    }

    .backtitle {
        margin: -22px 0 0 -15px;
        position: absolute;
        background-color: #FF6060;
        width: 100%;
        max-width: 415px;
        height: 20px;
        border-radius: 20px;
    }

    .main3sub2 {
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 361px) and (max-width: 768px) {
    .backtitle {
        margin: -16px 0 0 -8px;
        position: relative;
        background-color: #FF6060;
        width: 100%;
        max-width: 470px;
        height: 13px;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 307px) and (max-width: 360px) {
    .backtitle {
        margin: -16px 0 0 -8px;
        position: relative;
        background-color: #FF6060;
        width: 100%;
        max-width: 100px;
        height: 13px;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 278px) and (max-width: 306px) {
    .backtitle {
        margin: -16px 0 0 -8px;
        position: relative;
        background-color: #FF6060;
        width: 100%;
        max-width: 336px;
        height: 13px;
        border-radius: 20px;
    }
}

@media only screen and (max-width: 285px) {
    .imggotas {
        display: none;
    }
}

@media only screen and (max-width: 277px) {
    .backtitle {
        margin: -16px 0 0 -8px;
        position: relative;
        background-color: #FF6060;
        width: 100%;
        max-width: 100px;
        height: 13px;
        border-radius: 20px;
    }
}

@media only screen and (max-width: 231px) {
    * {
        word-break: break-all;
    }
}