@media only screen and (min-width: 1025px) {
    .header {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
  .header img {
    width: 220px;
    height: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .header img {
    width: 160px;
    height: auto;
  }

  .header {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 1024px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 100px;
        color: #24289B;
        padding: 20px 30px 20px 30px;
        gap: 5px;
      }

      .header p {
        color: white;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        margin: 0;
        cursor: pointer;
    }
      
      .logo img {
        display: flex;
        width: 147px;
        height: auto;
      }
      
      .sidemenu {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        max-width: 414px;
        background-color: #2F67F8;
        color: #fff;
        padding: 20px;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        box-sizing: border-box;
        z-index: 10;
        padding: 70px 0 70px 0;
      }
      
      .sidemenu.open {
        transform: translateX(0);
      }

      .bar {
        height: 2px;
        background-color: #FF6060;
        width: 0;
        border-radius: 100px;
        transition: all 0.3s;
    }
    
    .barAnimation {
        width: 100%;
    }

    .iconheadermob {
        display: flex;
    }

      .overlay {
        background: rgba(0, 0, 0, 0.116);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        z-index: 9;
      }
      
      .overlay.active {
        opacity: 1;
        visibility: visible;
      }

      .header button {
        background-color: #FF6060;
        border-radius: 100px;
        padding: 13px 25px 13px 25px;
        border: none;
        color: white;
        font-weight: bold;
        transition: all 0.3s;
        cursor: pointer;
    }
    
    .header button:hover {
        transition: all 0.3s;
        background-color: #ff3030;
    }

    .menucontent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      height: 100vh;
      max-height: 100%;
      overflow-y: auto;
    }

    .menulist {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
    }
}

@media only screen and (max-width: 250px) {
  .header {
    flex-wrap: wrap;
    justify-content: center;
  }
}
  