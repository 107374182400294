.container {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  .content {
    background-color: #F8F8FB;
    border-radius: 12px;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-sizing: border-box;
  }
  
  .indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8px;
  }
  
  .indicator {
    width: 20px;
    height: 5px;
    border-radius: 100px;
    background-color: #24289b46;
    margin: 0 2px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .indicator:hover {
    background-color: #24289ba9;
  }
  
  .active {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: #24289B;
    transition: all 0.3s;
  }

  .active:hover {
    background-color: #24289B;
  }

  .arrow {
    display: flex;
    color: #2F67F8;
    transition: all 0.3s;
    cursor: pointer;
  }

  .arrow:hover {
    color: #083dc4;
    transition: all 0.3s;
  }

  .block1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .block1 img {
    width: 35px;
    height: auto;
  }

  .content p {
    color: #525252;
  }

  .block2 p {
    font-style: italic;
  }

@media only screen and (min-width: 1025px) {
  .arrowmobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .arrowdesktop {
    display: none;
  }

  .arrowmodelmobile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
}