.container {
    background-color: #2F67F8;
    padding: 35px 25px 25px 25px;
    border-radius: 12px;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.container strong {
    color: #FFB420;
    font-size: 65px;
    line-height: 1;
}

.container span {
    color: #FFB420;
    font-weight: bold;
    font-size: 20px;
}

.container h3 {
    color: white;
    font-size: 28px;
}

.container p {
    color: white;
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.container button {
    background-color: #FF6060;
    border-radius: 100px;
    padding: 13px 25px 13px 25px;
    border: none;
    color: white;
    font-weight: bold;
    transition: all 0.3s;
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    margin-top: 10px;
}

.container button:hover {
    transition: all 0.3s;
    background-color: #ff3030;
}

@media only screen and (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .header h3 {
        text-align: center;
    }

    .header p {
        text-align: center;
    }
}

@media only screen and (max-width: 1024px) {
    .container {
        width: 100%;
        max-width: 440px;
    }
}